.status {
    margin: 79px auto 0;
    display: flex;
    justify-content: center;
    text-align: center;
    max-width: 1140px;
}

.status__text {
    font-family: 'Inter', 'Arial', 'Helvetica', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 40px;
    color: #FFFFFF;
}

@media screen and (max-width:1279px) {    
    .status {
        margin: 79px 50px 0;
    }
}


@media screen and (max-width:1023px) {    
    .status {
        margin: 79px 30px 0;
    }
}

@media screen and (max-width:767px) {
    .status {
        margin: 79px 10px 0;
    }
    .status__text {
        font-size: 15px;
        line-height: 20px;
    }
}