.dates {
    display: flex;
    flex-direction: column;
    width: fit-content;
    margin: 0 auto;
}

.date {
    display: flex;
    flex-direction: row;
    margin: 0 0 70px;
    position: relative;
    width: calc(100vw - 140px);
    max-width: 940px;
}

.date_reverse {
    flex-direction: row-reverse;
}

.date:last-of-type {
    margin: 0;
}

.date__image {
    height: 400px;
    width: calc(50% - 70px);
    margin: 0;
    position: relative;
}

.date__image-watermark {
    content: '';
    width: 200px;
    height: 200px;
    position: absolute;
    top: 0;
    right: 0;
    background-image: url("../../images/done-right.svg");
    z-index: 10;
}

.date__image-watermark_left {
    content: '';
    width: 200px;
    height: 200px;
    position: absolute;
    top: 0;
    left: 0;
    background-image: url("../../images/done-left.svg");
    z-index: 10;
}

.date__buttons {
    width: 60px;
    margin: 0 40px;
}

.date__info {
    width: calc(50% - 70px);
    margin: 0;
    background-color: #EE34E7;
    border-radius: 9px;
}

.date__info_visited {
    background-color: #DC893D;
}

.date__name {
    display: block;
    height: calc(15% - 20px);
    color: #202020;
    text-align: center;
    font-family: 'Inter', 'Arial', 'Helvetica', sans-serif;
    font-size: 35px;
    font-style: normal;
    font-weight: 500;
    line-height: 40px;
    padding: 15px 0 5px;
    margin: 0 auto;
    width: 70%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.date__description {
    color: #202020;
    text-align: center;
    font-family: 'Inter', 'Arial', 'Helvetica', sans-serif;
    font-size: 13.73px;
    font-style: normal;
    font-weight: 400;
    line-height: 16.73px;
    padding: 0;
    margin: 10px auto;
    width: 90%;
    height: calc(55% - 50px);
    max-height: 170px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.date__description_middle {
    height: calc(60% - 40px);
    max-height: 200px;
}

.date__description_large {
    height: calc(65% - 30px);
    max-height: 230px;
}

.date__text-item {
    margin: 0 auto 10px;
    width: 90%;
    height: 5%;
    display: flex;
    flex-direction: row;
}

.date__text-item_half {
    width: calc(45%-10px);
    margin: 0 20px 0 0;
    height: 100%;
}

.date__text-item_half:last-of-type {
    margin: 0;
}
.date__text-item-name {
    color: #202020;
    font-family: 'Inter', 'Arial', 'Helvetica', sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 14px;
    padding: 0;
    margin: 0 5px 0 0;
}

.date__text-item-value {
    color: #202020;
    font-family: 'Inter', 'Arial', 'Helvetica', sans-serif;
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
    padding: 0;
    margin: 0;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.date__text-item-link {
    color: #202020;
    text-decoration: none;
    font-family: 'Inter', 'Arial', 'Helvetica', sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
    padding: 0;
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; 
}

.date__text-items {
    display: flex;
    flex-direction: row;
    width: 90%;
    margin: 0 auto 10px;
    height: 5%;
}

.date__tags {
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    height: calc(15% - 20px);
    width: 90%;
    padding: 10px 0;;
}

.date__tag {
    margin: 0 20px 0 0;
    background-color: #DC893D;
    color: #202020;
    font-family: 'Inter', 'Arial', 'Helvetica', sans-serif;
    font-size: 8px;
    font-style: normal;
    font-weight: 700;
    line-height: 14px;
    padding: 0;
    width: calc((100% - 60px)/4);
    height: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.date__tag_visited {
    background-color: #EE34E7;
}

.date__tag:last-of-type {
    margin: 0;
}

.date__buttons {
    display: flex;
    flex-direction: column;
}

.date__button {
    width: 60px;
    height: 30px;
    background: none;
    background-image: url(../../images/visit.svg);
    background-size: contain;
    padding: 0;
    background-repeat: no-repeat;
    margin: 20px 0 0;
    border: none;   
}

.date__button_visit {
    background-image: url(../../images/visit.svg);
}

.date__button_visit_on {
    background-image: url(../../images/revisit.svg);
}

.date__button_like {
    background-image: url(../../images/relike.svg);
}

.date__button_like_on {
    background-image: url(../../images/like.svg);
}

.date__button_delete {
    background-image: url(../../images/delete.svg);
}

.date__button_edit {
    background-image: url(../../images/edit.svg);
}


@media screen and (max-width:1024px) {
    .date {
        width: calc(100vw - 100px);
    }
    .date__buttons {
        width: 50px;
        margin: 0 30px;
    }
    .date__button {
        width: 50px;
    }
    .date__image {
        width: calc(50% - 55px);
        height: 350px;
    }
    .date__info {
        width: calc(50% - 55px);
        height: 350px;
    }
    .date__name {
        font-size: 30px;
        line-height: 35px;
    }
    .date__description {
        font-size: 12px;
        line-height: 14.1px;
        max-height: 142.5px;
    }
    .date__description_middle {
        max-height: 170px;
    }
    .date__description_large {
        max-height: 197.5px;
    }
    .date__text-item-name {

    }
    .date__text-item-value {

    }
    .date__text-item-link {

    }
    .date__tag {
        font-size: 9px;
        line-height: 14.1px;
    }
    .date__text-item_half {
        max-width: calc((350px - 20px)/2);
    }
}


@media screen and (max-width:767px) {
    .date {
        width: calc(100vw - 60px);
        flex-direction: column;
    }
    .date__buttons {
        width: fit-content;
        margin: 20px auto;
        flex-direction: row;
    }
    .date__button {
        width: 40px;
        margin: 0 15px;
        height: 20px;
    }
    .date__image {
        width: 100%;
        height: 300px;
    }
    .date__info {
        width: 100%;
        height: 300px;
    }
    .date__name {
        max-width: 177px;
        font-size: 25px;
        line-height: 30px;
    }
    .date__description {
        font-size: 9px;
        line-height: 11.1px;
        max-height: 135px;
        height: calc(55% - 30px);
    }
    .date__description_middle {
        max-height: 154px;
        height: calc(60% - 21px);
    }
    .date__description_large {
        max-height: 175px;
        height: calc(65% - 20px);
    }
    .date__text-item-name {
        font-size: 8px;
        line-height: 10px;
    }
    .date__text-item-value {
        font-size: 8px;
        line-height: 10px;
    }
    .date__text-item-link {
        font-size: 8px;
        line-height: 10px;
    }
    .date__tag {
        width: calc((100% - 30px)/4);
        margin: 0 10px 0 0;
        font-size: 6.5px;
        line-height: 14.1px;
    }
    .date__text-items {
        margin-bottom: 0;
    }
    .date__text-item {
        margin-bottom: 5px;
        width: 90%;
        max-width: none;
    }
    .date__text-item_half {
        max-width: calc((227px - 20px)/2);
    }
}

@media screen and (max-width:320px) {
    /* .date__image {
        height: 200px;
        width: 260px;
    } */
}