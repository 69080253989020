.header {
    align-items: center;
    justify-content: space-between;
    display: flex;
    height: 74px;
    width: calc(100vw - 140px);
    max-width: 940px;
    background-color: #202020;
    margin: 0 auto;
}

.header__logo {
    background-image: url(../../images/logo.svg);
    width: 38px;
    height: 38px;
}

.header__logo_login {
    background-image: url(../../images/logo_login.svg);
}

.header__button {
    background-color: #DC893D;
    color: #202020;
    font-family: 'Inter', 'Arial', 'Helvetica', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    margin-left: 20px;
    border: none;
    border-radius: 3px;
    height: 36px;
    min-width: 113px;
    transition: opacity 0.6s;
}

.header__button_login {
    background-color: #EE34E7;
}

.header__button:hover {
    opacity: 0.6;
    cursor: pointer;
}

.header__link {
    color: #FFFFFF;
    font-family: 'Inter', 'Arial', 'Helvetica', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    text-decoration: none;
    transition: opacity 0.6s;
    margin: 0;
}


.header__link:hover {
    opacity: 0.6;
    cursor: pointer;
}

@media screen and (max-width:1024px) {
    .header {
        width: calc(100vw - 100px);
    }
}

@media screen and (max-width:767px) {
    .header {
        width: calc(100vw - 60px);
    }
    .header__button {
        font-size: 10px;
        line-height: 12px;
        margin-left: 10px;
        min-width: 60px;
    }
    .header__link {
        font-size: 10px;
        line-height: 12px;
    }
}