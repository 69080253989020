.sign {
    display: block;
    width: 396px;
    height: fit-content;
    margin: 40px auto 65px;
}

.sign__title {
    font-family: 'Inter', 'Arial', 'Helvetica', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 26px;
    color: #FFFFFF;
    margin: 0 auto 40px;
    box-sizing: border-box;
    text-align: center;
}

.sign__form {
    margin: 0;
    clear: both;
}

.sign__input {
    padding: 0 0 5px;
    border: none;
    border-bottom: 1px solid #8B8B8B;
    margin: 0;
    font-family: 'Inter', 'Arial', 'Helvetica', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #FFFFFF;
    background: none;
}

.sign__input:focus {
    outline-style: none;
    border-bottom: 1px solid #DC893D
}

.sign__input-name {
    font-family: 'Inter', 'Arial', 'Helvetica', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    color: #8B8B8B;
    margin: 0 0 5px;
}

.sign__input_active:invalid {
    color: #EE34E7
}

.sign__input-error {
    min-height: 20px;
    margin: 0;
    padding: 5px 0 15px;
    font-family: 'Inter', 'Arial', 'Helvetica', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 8px;
    line-height: 10px;
    color: #EE34E7;
}

.sign__inputs {
    border: none;
    display: flex;
    flex-direction: column;
    padding: 0;
    margin: 0;
}

.sign__link-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.sign__link-description {
    font-family: 'Inter', 'Arial', 'Helvetica', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: #FFF;
    margin: 0;
}

.sign__link {
    display: block;
    font-family: 'Inter', 'Arial', 'Helvetica', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #DC893D;
    margin: 0 0 0 6px;
    text-decoration: none;
    transition: opacity 0.5s;
}

.sign__link_login {
    color: #EE34E7;
}

.sign__link:hover {
    opacity: 0.6;    
    cursor: pointer;  
}

.sign__save-button {
    padding: 0;
    border: none;
    margin: 15px 0;
    background-color:  #DC893D;
    width: 100%;
    height: 45px;
    font-family: 'Inter', 'Arial', 'Helvetica', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: #FFFFFF;
    border-radius: 3px;
}

.sign__save-button:hover {
    opacity: 0.85;
    transition: opacity 0.5s;
    cursor: pointer;
}

.sign__save-button:disabled,  .sign__save-button:disabled:hover{
    background-color: #8B8B8B;
    opacity: 1;
    border: none;
    cursor: default;
}

.sign__form-error {
    font-family: 'Inter', 'Arial', 'Helvetica', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 13px;
    text-align: center;
    color: #DC893D;
    min-height: 26px;
    margin: 184px auto 0;
}

.sign__form-error_middle {
    margin-top: 104px;
}

.sign__form-error_closer {
    margin-top: 26px;
}

.sign__form-error_active {
    color: #EE34E7;
}

@media screen and (max-width:767px) {
    .sign {
        width: 260px;
    }
    
    .sign__title {
        margin: 0 auto 80px;
        text-align: center;
    }
    
    .sign__link-description {
        font-size: 12px;
        line-height: 15px;
    }
    
    .sign__link {
        font-size: 12px;
        line-height: 15px;
    }
    
    .sign__save-button {
        font-size: 12px;
        line-height: 15px;
    }
}