.popup {
    opacity: 0;
    visibility: hidden;
    position: absolute;
    top:0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(20, 20, 20, 0.5);
    display: flex;
    justify-content: center;
    transition: visibility 0.5s, opacity 0.5s linear;
}

.popup_opened {
    visibility: visible;
    opacity: 1;
}

.popup__container {
    width: 60%;
    height: fit-content;
    background-color: #202020;
    box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    position: relative;
    margin-top: 20px;
    padding: 100px;
}

.popup__title {
    font-family: 'Inter', 'Arial', 'Helvetica', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 26px;
    color: #FFF;
    margin: 0 auto 40px;
    box-sizing: border-box;
    text-align: center;
}

.popup__description {
    font-family: 'Inter', 'Arial', 'Helvetica', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18x;
    line-height: 20px;
    color: #DC893D;
    margin: 0;
    box-sizing: border-box;
    text-align: center;
}

.popup__close-button {
    width: 32px;
    height: 32px;
    background-image: url(../../images/Close\ Icon.svg);
    background-size: cover;
    position: absolute;
    right: 20px;
    top: 20px;
    transition: opacity 0.5s;
    border: none;
    padding: 0;
    background-color: rgba(0, 0, 0, 0);
}

.popup__close-button:hover {
    opacity: 0.6;
    cursor: pointer;
}

.popup__form {
    margin: 0;
    clear: both;
}

.popup__inputs {
    border: none;
    display: flex;
    flex-direction: column;
    padding: 0;
    margin: 0;
}

.popup__input {
    padding: 0 0 5px;
    border: none;
    border-bottom: 1px solid #8B8B8B;
    margin: 0;
    font-family: 'Inter', 'Arial', 'Helvetica', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #FFFFFF;
    background: none;
}

.popup__input:focus {
    outline-style: none;
    border-bottom: 1px solid #DC893D
}

.popup__input::placeholder {
    font-family: 'Inter', 'Arial', 'Helvetica', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #DC893D;
    opacity: 0.6;
}

.popup__input-name {
    font-family: 'Inter', 'Arial', 'Helvetica', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    color: #8B8B8B;
    margin: 0 0 5px;
}

.popup__input_active:invalid {
    color: #EE34E7
}

.popup__input-error {
    min-height: 20px;
    margin: 0;
    padding: 5px 0 15px;
    font-family: 'Inter', 'Arial', 'Helvetica', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 8px;
    line-height: 10px;
    color: #EE34E7;
}

.popup__link-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.popup__link-description {
    font-family: 'Inter', 'Arial', 'Helvetica', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: #FFF;
    margin: 0;
}

.popup__link {
    display: block;
    font-family: 'Inter', 'Arial', 'Helvetica', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #DC893D;
    margin: 0 0 0 6px;
    text-decoration: none;
    transition: opacity 0.5s;
}

.popup__link:hover {
    opacity: 0.6;    
    cursor: pointer;  
}

.popup__save-button {
    padding: 0;
    border: none;
    margin: 15px 0;
    background-color:  #DC893D;
    width: 100%;
    height: 45px;
    font-family: 'Inter', 'Arial', 'Helvetica', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: #FFFFFF;
    border-radius: 3px;
}

.popup__save-button:hover {
    opacity: 0.85;
    transition: opacity 0.5s;
    cursor: pointer;
}

.popup__save-button:disabled,  .popup__save-button:disabled:hover{
    background-color: #8B8B8B;
    opacity: 1;
    border: none;
    cursor: default;
}

.popup__form-error {
    font-family: 'Inter', 'Arial', 'Helvetica', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 13px;
    text-align: center;
    color: #DC893D;
    min-height: 26px;
    margin: 24px auto 0;
}

.popup__form-error_active {
    color: #EE34E7;
}

.popup__input-checkboxes {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 10px;
    row-gap: 10px;
    margin-top: 10px;
}

.popup__input-checkbox-container {
    display: flex;
    flex-direction: row;
    margin: 0;
}

.popup__input-checkbox-name {
    font-family: 'Inter', 'Arial', 'Helvetica', sans-serif;
    margin: 0;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #DC893D;
}

.popup__input-checkbox-name:hover {
    cursor: default;
}

.popup__input-checkbox-name_off {
    color: #EE34E7;
}

.popup__input-checkbox{
    width: 16px;
    height: 16px;
    background-image: url(../../images/check-on.svg);
    border: none;
    padding: 0;
    margin: 0 10px 0 0;
    background-color: #202020;
}

.popup__input-checkbox_off{
    background-image: url(../../images/check-off.svg);
}

.popup__input-checkbox:hover {
    opacity: 0.85;
    transition: opacity 0.5s;
    cursor: pointer;
}

.popup__input-container {
    display: flex;
    flex-direction: column;
    height: fit-content;
}

.popup__inputs-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 30px;
}

@media screen and (max-width:1024px) {    
    .popup__inputs-container {
        grid-template-columns: repeat(1, 1fr);
        column-gap: 30px;
    }
}

