.search-form {
    margin: 0 auto;
    height: 70px;
    background-color: #DC893D;
    border-radius: 9px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: calc(100vw - 140px);
    max-width: 940px;
}

.search-form__selectors {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.search-form__icon {
    background-image: url(../../images/findicon_small.svg);
    background-position: center;
    height: 22px;
    width: 22px;
    margin: 0 10px;
    padding: 0;
}

.search-form__inputs {
    border: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0;
    margin: 0;
    border-right: 1px solid #202020;
}

.search-form__input {
    padding: 0;
    border: none;
    margin: 0;
    font-family: 'Inter', 'Arial', 'Helvetica', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    color: #202020;
    background: none;
    border-bottom: 1px solid #DC893D;
    width: calc(100vw - 140px - 725px);
    max-width: calc(940px - 725px);
}

.search-form__input::placeholder {
    color: #202020;
    opacity: 0.6;
}

.search-form__input:focus {
    outline-style: none;
    border-bottom: 1px solid #202020;
}

.search-form__input:focus::placeholder {
    color: #202020;
}

.search-form__input_active {
    border-bottom: 1px solid #EE34E7;
    color: #EE34E7;
}

.search-form__input_active:focus {
    border-bottom: 1px solid #EE34E7;
}


.search-form__input_active::placeholder {
    color: #EE34E7
}

.search-form__button {
    border: none;
    background-color:  #DC893D;
    background-image: url(../../images/findicon.svg);
    width: 34px;
    height: 34px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    margin: 0 10px;
    padding: 0;
}

.search-form__button:hover {
    opacity: 0.85;
    transition: opacity 0.5s;
    cursor: pointer;
}

.search-form__button:disabled,  .search-form__button:disabled:hover {
    opacity: 0.5;
    border: none;
    cursor: default;
}

.search-form__tumbler-containers {
    display: flex;
    flex-direction: row;
}

.search-form__tumbler-container {
    margin: 0;
    height: 40px;
    padding: 0 10px;
    border-left: 1px solid #202020;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.search-form__tumbler-name {
    font-family: 'Inter', 'Arial', 'Helvetica', sans-serif;
    margin: 0 0 0 10px;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: #202020;
    text-wrap: nowrap;
}

.search-form__tumbler-name:hover {
    cursor: default;
}

.search-form__tumbler{
    width: 36px;
    height: 20px;
    background-image: url(../../images/smalltumbonn.svg);
    border: none;
    background-color: #DC893D;
    background-size: cover;
    padding: 0;
    background-repeat: no-repeat;
}

.search-form__tumbler_off{
    background-image: url(../../images/smalltumboff.svg);
}

.search-form__tumbler:hover {
    opacity: 0.85;
    transition: opacity 0.5s;
    cursor: pointer;
}

.search-form__select {
    position: relative;
}
.search-form__select-container {
    margin: 0 10px;
    padding: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    width: 116px;
    background-color: #202020;
}

.search-form__select-container-text {
    font-family: 'Inter', 'Arial', 'Helvetica', sans-serif;
    margin: 0 0 0 5px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #DC893D;
    width: 97px;
}

.search-form__select-container-text_active {
    color: #EE34E7;
}

.search-form__select-container:hover {
    opacity: 0.85;
    transition: opacity 0.5s;
    cursor: pointer;
}

.search-form__select-container-icon_down {
    width: 16px;
    height: 16px;
    background-image: url(../../images/select-down.svg);
    background-size: cover;
    padding: 0;
    background-repeat: no-repeat;
    margin: 0 5px;
}

.search-form__select-container-icon_up {
    background-image: url(../../images/select-up.svg);
}

.search-form__checkboxes-container {
    width: 104px;
    background-color: #202020;
    padding: 5px;
    border: 1px solid #8B8B8B;
    position: absolute;
    top: 32px;
    left: 10px;
    display: flex;
    flex-direction: column;
    visibility: hidden;
    z-index: 2;
}

.search-form__checkboxes-container_active {
    visibility: visible;
}

.search-form__checkbox-container {
    display: flex;
    flex-direction: row;
    margin: 0 0 10px;
    align-items: center;
}

.search-form__checkbox-container:last-of-type {
    margin: 0;
}

.search-form__checkbox-name {
    font-family: 'Inter', 'Arial', 'Helvetica', sans-serif;
    margin: 0;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #DC893D;
}

.search-form__checkbox-name:hover {
    cursor: default;
}

.search-form__checkbox-name_off {
    color: #EE34E7;
}

.search-form__checkbox{
    width: 16px;
    height: 16px;
    background-image: url(../../images/check-on.svg);
    border: none;
    padding: 0;
    margin: 0 10px 0 0;
    background-color: #202020;
}

.search-form__checkbox_off{
    background-image: url(../../images/check-off.svg);
}

.search-form__checkbox:hover {
    opacity: 0.85;
    transition: opacity 0.5s;
    cursor: pointer;
}

@media screen and (max-width:1024px) {
    .search-form {
        width: calc(100vw - 100px);
        flex-direction: column;
        height: fit-content;
    }

    .search-form__input {
        width: 100%;
        max-width: none;
    }

    .search-form__inputs {
        border: none;
    }

    .search-form__tumbler-container:first-of-type {
        border: none;
    }

    .search-form__selectors {
        width: 480px;
        margin: 10px 0;
    }

    .search-form__tumbler-containers {
        margin: 0 0 10px;
    }

    .search-form__select-container {
        width: 150px;
    }

    .search-form__checkboxes-container_active {
        width: 140px;
    }

    .search-form__select-container-text {
        width: 131px;
        font-size: 18px;
        line-height: 30px;
    }

    .search-form__select-container-icon_down {
        width: 18px;
        height: 18px;
    }

    .search-form__checkboxes-container {
        top: 36px;
        left: 9px;
    }

    .search-form__checkbox-name {
        font-size: 16px;
        line-height: 20px;
    }

}

@media screen and (max-width:768px) {
    .search-form {
        width: calc(100vw - 60px);
    }

    .search-form__inputs {
        border: none;
    }

    .search-form__select {
        margin: 10px 0 0;
    }

    .search-form__selectors {
        width: 100%;
        flex-direction: column;
    }

    .search-form__tumbler-container {
        height: 30px;
        padding: 0 5px;
    }

    .search-form__tumbler {
        width: 31px;
        height: 17px;
    }
    .search-form__tumbler-name {
        margin: 0 0 0 5px;
        font-size: 12px;
        line-height: 16px;

    }

}