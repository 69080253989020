.main {
    align-items: center;
    display: flex;
    width: calc(100vw - 140px);
    max-width: 1140px;
    background-color: #202020;
    margin: 0 auto;
    flex-direction: column;
}

.main__description {
    max-width: 750px;
    margin: 0 0 18px;
    font-family: 'Inter', 'Arial', 'Helvetica', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    color: #FFF;
    padding: 0;
    text-align: center;
}

.main__section {
    display: flex;
    flex-direction: row;
    max-width: 1140px;
    margin: 25px auto 0;
}

.main__grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 30px;
    row-gap: 30px;
    margin: 0 62px 0 0;
}

.main__grid-image{
    height: 110px;
    width: 140px;
}

.main__epigraph {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.main__quote {
    color: #EE34E7;
    text-align: right;
    font-family: 'Inter', 'Arial', 'Helvetica', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    margin: 0;
}

.main__sign {
    color: #DC893D;
    text-align: right;
    font-family: 'Inter', 'Arial', 'Helvetica', sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
    width: 175px;
    margin: 0;
}


@media screen and (max-width:1023px) {    
    .main__grid-image{
        height: 90px;
        width: 120px;
    }
    .main__grid {
        column-gap: 15px;
        row-gap: 15px;
        margin: 0 32px 0 0;
    }
}

@media screen and (max-width:767px) {
    .main {
        width: calc(100vw - 60px);
    }
    .main__description {
        font-size: 14px;
        line-height: 16px;
    }
    .main__grid {
        grid-template-columns: repeat(2, 1fr);
    }
    .main__quote {
        line-height: 18px;
    }
}

@media screen and (max-width:551px) {
    .main__description {
        font-size: 10px;
        line-height: 12px;
    }
    .main__section {
        flex-direction: column;
        max-width: 1140px;
        margin: 25px auto 0;
    }
    .main__grid {
        margin: 0 0 20px;
        justify-items: center;
    }
    .main__grid-image{
        height: 90px;
        width: 120px;
    }
    .main__epigraph {
        max-width: 318px;
    }
}

@media screen and (max-width:320px) {
    .main__grid {
        grid-template-columns: repeat(1, 1fr);
        column-gap: 0;
        margin: 0 0 20px;
        justify-items: center;
    }
    .main__grid-image{
        height: 150px;
        width: 240px;
    }
}