.sort-form {
    margin: 30px auto 80px;
    height: 70px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: calc(100vw - 140px);
    max-width: 940px;
}

.sort-form__inputs {
    border: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0;
    margin: 0 34px 0 0;
    background-color: #EE34E7;
    border-radius: 9px;
    width: fit-content;
    height: 70px;
}

.sort-form__starter {
    display: flex;
    flex-direction: row;
}

.sort-form__icon {
    background-image: url(../../images/sort-icon.svg);
    background-position: center;
    height: 22px;
    width: 22px;
    margin: 0 10px;
    padding: 0;
}

.sort-form__text {
    padding: 0;
    margin: 0;
    font-family: 'Inter', 'Arial', 'Helvetica', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 22px;
    width: 138px;
    color: #202020;
    opacity: 0.6;
}

.sort-form__text:hover {
    cursor: default;
}

.sort-form__button {
    border: none;
    align-items: center;
    padding: 0;
    margin: 0;
    background-color: #DC893D;
    border-radius: 9px;
    width: 196px;
    text-align: center;
    font-family: 'Inter', 'Arial', 'Helvetica', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 17px;
    line-height: 22px;
    color: #202020;
    height: 70px;
    min-width: 167px;
}

.sort-form__button:hover {
    opacity: 0.85;
    transition: opacity 0.5s;
    cursor: pointer;
}

.sort-form__inputs-container {
    margin: 0 15px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-content: space-between;
    width: 475px;
}

.sort-form__radio-container {
    margin: 0 10px 0 0;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.sort-form__radio-container:hover {
    cursor: pointer;
    opacity: 0.6;
    transition: opacity 0.5s;
}

.sort-form__radio-container:last-of-type {
    margin: 0;
}

.sort-form__radio {
    margin: 0 8px 0 0;
    border: none;
    background: none;
    background-image: url(../../images/radio-off.svg);
    height: 16px;
    width: 16px;
    padding: 0;
}

.sort-form__radio_active {
    background-image: url(../../images/radio-on.svg);
}

.sort-form__radio-name {
    color: #202020;
    font-family: 'Inter', 'Arial', 'Helvetica', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    margin: 0;
    padding: 0;
}

@media screen and (max-width:1024px) {
    .sort-form {
        width: calc(100vw - 100px);
    }

    .sort-form__inputs-container {
        display: grid;
        grid-template-columns: 130px 130px;
        column-gap: 5px;
        width: 265px;
    }

    .sort-form__inputs {
        justify-content: center;
        width: 100%;
    }
}

@media screen and (max-width:768px) {
    .sort-form {
        width: calc(100vw - 60px);
        flex-direction: column;
        height: fit-content;
        margin-bottom: 30px;
    }

    .sort-form__inputs-container {
        display: grid;
        grid-template-columns: 130px 130px;
        column-gap: 5px;
        width: 265px;
        margin-bottom: 20px;
    }

    .sort-form__inputs {
        margin: 0 0 30px;
        align-items: center;
        flex-direction: column;
        height: fit-content;
    }

    .sort-form__button {
        width: 100%;
        height: 100px;
    }

    .sort-form__starter {
        margin: 20px 0;
    }
}