.footer {
    margin: auto auto 0;
    width: calc(100vw - 140px);
    max-width: 940px;
    padding: 70px 0;
}

.footer__tittle {
    font-family: 'Inter', 'Arial', 'Helvetica', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 15px;
    text-align: center;
    color: #8B8B8B;
    margin: 0 0 20px;
}

.footer__info {
    padding-top: 20px;
    border-top: 1px solid #424242;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.footer__year {
    font-family: 'Inter', 'Arial', 'Helvetica', sans-serif;
    margin: 0;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 15px;
    color: #DC893D;
}

.footer__links {
    display: flex;
    height: fit-content;
}

.footer__link {
    font-family: 'Inter', 'Arial', 'Helvetica', sans-serif;
    text-decoration: none;
    margin: 0 20px 0 0;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 15px;
    color: #EE34E7;
}

.footer__link:last-of-type {
    margin: 0;
}

@media screen and (max-width:1024px) {
    .footer {
        width: calc(100vw - 100px);
    }
}

@media screen and (max-width:767px) {
    .footer {
        width: calc(100vw - 60px);
    }
}

@media screen and (max-width:551px) {
    .footer__tittle {
        font-size: 10px;
        line-height: 12px;
    }
    .footer__year {
        font-size: 10px;
        line-height: 12px;
    }    
    .footer__link {
        font-size: 10px;
        line-height: 12px;
    }
}