.name {
    padding: 0;
    display: inline-flex;
    flex-direction: row;
    margin: 25px auto;
}

.name__title {
    margin: 0 20px 0 0;
    font-family: 'Inter', 'Arial', 'Helvetica', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 69px;
    line-height: 74px;
    color: #EE34E7;
    padding: 0;
}

.name__title_second {
    margin: 0;
    color: #DC893D;
}

@media screen and (max-width:767px) {
    .name__title {
        font-size: 45px;
        line-height: 55px;
    }
}